<template>
    <div>
        <div class="sidebarClose mb-3">
            <span class="closeModal" @click="toggleSidebar">
                <span class="icon-close"></span>
            </span>
        </div>
        <div class="form-group mb-4">
            <h3>Location</h3>
            <multiselect
                placeholder="Select Location"
                v-model="location"
                :options="locations"
                :custom-label="locationLabel"
                @select="getSelectedLocation"
                @remove="removedSelectedLocation"
            >
            </multiselect>
        </div>
        <div class="form-group mb-4">
            <h3>Find your Property</h3>
            <input type="text" v-model="filterData.keywords" class="univ-input" placeholder="What are you looking for?">
        </div>
        <div class="form-group mb-4">
            <h3>Property For</h3>
            <ul>
                <li>
                    <input class="styled-checkbox" type="radio" id="sale" value="sale" v-model="filterData.purpose" /><label for="sale"> For Buy </label>
                </li>
                <li>
                    <input class="styled-checkbox" type="radio" id="rent" value="rent" v-model="filterData.purpose" /><label for="rent"> For Rent</label>
                </li>
            </ul>
        </div>
        <div class="form-group mb-4">
            <h3>Property Type</h3>
            <ul>
                <li v-for="(item,i) in categories" :key="i">
                    <input class="styled-checkbox" :id="'feature'+i" type="radio" v-model="filterData.category" :value="item.slug">
                    <label :for="'feature'+i">{{ item.title }}</label>
                </li>
            </ul>
        </div>
        
        <!-- <div class="form-group mb-4">
            <h3>Area</h3>
            <div class="row">
                <div class="col-xl-6">
                    <input type="text" class="univ-input" placeholder="1234">
                </div>
                <div class="col-xl-6">
                    <select name="" id="" class="univ-input">
                        <option value="">Sqft</option>
                        <option value="">Anna</option>
                        <option value="">Ropani</option>
                        <option value="">Dhur</option>
                        <option value="">Haat</option>
                    </select>
                </div>
            </div>
        </div> -->
        <div class="form-group text-center reset-btn">
            <button class="univ-btn w-100" @click="filters">Search</button>
            <a href="javascript:void(0);" @click="resetFileter">Reset Search</a>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: "PropertyFilter",
    components: { 
        Multiselect, 
    },
    data() {
        return {
            location: "",
            filterData:{
                purpose:this.$route.query.purpose,
                keywords:this.$route.query.keywords,
                category:this.$route.query.category,
                location:this.$route.query.location,
            },
            categories: [],
            locations: [],
        };        
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData  = response.data.data;
                app.categories = responseData.property_category;
                let locationRows = [];
                responseData.location.forEach(function (item) {
                    locationRows.push({name:item.name,slug:item.slug});
                });
                app.locations = locationRows;
            });
        },
        filters(){
            let app = this;
            this.$emit("onSearch", app.filterData);
            let filterUrl = Helper.objToUrlParams(app.filterData);
            app.$router.push({path:`/search?${filterUrl}`}); 
        },
        toggleSidebar() {
            let app = this;
            app.$emit('toggle-event', false);
        },
        resetFileter(){
            let app = this;
            app.clearFilter();
            app.$emit("onSearch", app.filterData);
            app.$router.push({path:'/properties'}); 
        },
        clearFilter(){
            let app = this;
            app.filterData.purpose = "";
            app.filterData.keywords = "";
            app.filterData.category = "";
            app.filterData.location = "";
        },
        locationLabel(option) {
            return `${option.name}`;
        },
        getSelectedLocation(option){
            let app = this;
            app.filterData.location = option.slug;
        },
        removedSelectedLocation(){
            let app = this;
            app.filterData.location = "";
        },
    },
    watch: {
        $route() {
            this.toggleSidebar();
        }
    }
}
</script>